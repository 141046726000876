<template>
  <div class="mx-3">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue" class="mb-3 mt-3" dark v-bind="attrs" v-on="on">
          {{ $t('Add New Request') }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="itemClicked(item)" style="cursor: pointer" v-for="item in items" :key="item.id">
          <v-list-item-title>{{
            invoiceTypeFormatter(item)
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-row>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.from-date')" type="date" v-model="filters.from" outlined dense>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :label="$t('hr.time.to-date')" type="date" v-model="filters.to" outlined dense>
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field v-model="filters.serial" :label="$t('Invoice Number')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.invoice_type_name" :label="$t('Invoice Name')" dense item-text="name"
          item-value="name" outlined :items="items" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.invoice_type" :label="$t('Invoice Type')" dense item-text="label"
          item-value="value" outlined :items="types" clearable></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.invoice_state" :label="$t('Purchase status')" dense item-text="name" item-value="value"
          outlined :items="stateInvoice" clearable></v-autocomplete>
      </v-col>
      <!-- <v-col md="3">
        <v-text-field
          v-model="filters.client_name"
          :label="$t('Client Name')"
          outlined
          dense
        ></v-text-field>
      </v-col> -->

      <v-col md="3">
        <v-text-field v-model="filters.total_amount" type="number" :label="$t('Total Value')" outlined dense></v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete v-model="filters.deliveried" :label="$t('Invoice State')" dense item-text="name"
          item-value="value" outlined :items="stateDeliveried" clearable></v-autocomplete>
      </v-col>
      <v-col md="6">
        <v-text-field v-model="filters.notes" :label="$t('notes')" outlined dense></v-text-field>
      </v-col>
      <v-col md="6"></v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
        </v-btn>
      </v-col>

    </v-row>



    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 py-5" hide-default-footer :items-per-page="10">
      <template v-slot:item="{ item }">
        <tr :class="{ 'background-row': shouldMakeRowBackground(item) }">
          <td>{{ item.id }}</td>
          <td>{{ item.request_serial }}</td>
          <td>{{ item.order_serial }}</td>
          <td>{{ item.request_date }}</td>
          <td>{{ item.order_date }}</td>
          <td>{{ item.invoice_type_name }}</td>
          <td>{{ item.client_account }}</td>
          <td>{{ item.total_amount }}</td>
          <td>{{ item.deliveried }}</td>
          <td>{{ item.notes }}</td>
          <td style="width: 10%;">
            <v-btn @click="invoiceTypeToHandler(item)" color="blue" icon>
              <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
            <deleteInvoice :path="path" :id="item.id" @record-deleted="getAll" />
          </td>
        </tr>
      </template>

    </v-data-table>
    <v-pagination v-if="!loading && items.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>
<script>
import axios from "axios";
import deleteInvoice from "../../components/core/delete.vue";
import { saveAs } from 'file-saver';
import i18n from "../../../../i18n";
export default {
  components: { deleteInvoice },
  computed: {
    shouldMakeRowBackground() {
      return (item) => {
        if (item.deliveried == "done") {
          return true;
        }
        return false;
      };
    }
  },
  data: () => ({
     stateInvoice: [
      {
        name:  i18n.t('Request'),
        value: "request",
      },
      {
        name:  i18n.t('Order'),
        value: "order",
      }
    ],
    stateDeliveried: [
      {
        name:  i18n.t('FULL'),
        value: "done",
      },
      {
        name:  i18n.t('PART'),
        value: "partial",
      },
      {
        name:  i18n.t('ZERO'),
        value: "none",
      },
    ],
    types: [
      {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
    ],
    tableItems: [],
    invoiceTypes: [],
    loading: false,
    path: 'inventories/invoice-request',
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t('id'), value: "id" },
      { text: i18n.t('Request Number'), value: "request_serial" },
      { text: i18n.t('Order Number'), value: "order_serial" },
      { text: i18n.t('Request Date'), value: "request_date" },
      { text: i18n.t('Order Date'), value: "order_date" },
      { text: i18n.t('Invoice Name'), value: "invoice_type_name" },
      { text: i18n.t('Vendor Name'), value: "client_account" },
      { text: i18n.t('Total Value'), value: "total_amount" },
      { text: i18n.t('status'), value: "deliveried" },
      { text: i18n.t('notes'), value: "notes" },
      { text: "", value: "actions" },
    ],
    items: [],
    filters: {
      from: null,
      to: null,
      id: null,
      serial: null,
      invoice_type_name: null,
      invoice_state: null,
      status: null,
      invoice_type: null,
      client_name: null,
      paid_amount: null,
      total_amount: null,
      notes: null,
    },
  }),
  methods: {
    clearFilter() {
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.id = undefined;
      this.filters.status = undefined;
      this.filters.serial = undefined;
      this.filters.invoice_state = undefined;
      this.filters.invoice_type_name = undefined;
      this.filters.invoice_type = undefined;
      this.filters.client_name = undefined;
      this.filters.paid_amount = undefined;
      this.filters.total_amount = undefined;
      this.filters.notes = undefined;
      this.page = 1;
      this.getAll();
    },
    async exportToExcel(queryParams) {
      try {
        const excel = await axios.get(`/inventories/invoices/export`, {
          params: {
            ...queryParams,
          },
          responseType: 'blob'
        });
        saveAs(excel.data)
      } catch (err) {
        console.log('err', err);
      } finally {
      }
    },
    invoiceTypeToHandler(item) {
      this.$router.push(
        `/edit-request/${item.id}?invoice_id=${item.invoice_type_id}&wear_house=${2}`
      );
    },
    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("/inventories/invoice-request/purchase", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        this.tableItems = res.data.data.map((el) => {
          const typeTemp = this.types.find(
            (typ) => el?.invoice_type?.type == typ.value
          );
          return {
            ...el,
            invoiceTypeName: el?.invoice_type?.name,
            clientAccountName: el?.client_account?.name,
            type: typeTemp ? typeTemp.label : " ",
            invoiceStatus: el.is_locked ? "Locked" : "UnLocked",
          };
        });

        this.total = res.data.meta.total;
      } finally {
        this.loading = false;
      }
    },
    invoiceTypeFormatter(invoiceType) {
      const invoiceTemp = [
      {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('sales'),
        value: "SALES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
      {
        label: i18n.t('sales refunds'),
        value: "SALES_REFUNDS",
      },
      {
        label: i18n.t('transportation'),
        value: "TRANSPORTATION",
      },
      ].find((el) => el.value == invoiceType?.type);
      let str = "";
      if (invoiceTemp) {
        str = `PR-${invoiceType.name}`;
      }
      return str;
    },
    itemClicked(v) {

      this.$router.push(`/newRequest?invoice_id=${v.id}&wear_house=${2}`);

    },
    invoiceTypeLabelFormatter(type) {
      return [
      {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
      ].find((el) => el.value == type).label;
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index?type=purchase");
      this.items = res.data.data.map((el) => ({
        ...el,
        invoiceArLabel: this.invoiceTypeLabelFormatter(el.type),
      }));
      console.log(this.items);
    },
  },
  created() {
    this.getInvoiceTypes();
  },
};
</script>
<style>
.background-row {
  background: #e6f3f2;
  /* font-weight: bold; */
}
</style>
